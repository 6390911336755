import { apiGet, apiPost } from './service'

export async function listMain(data) {
	return await apiPost("/api/Main/list", data);
}

export async function fullListMain(data) {
	return await apiPost("/api/Main/fulllist", data);
}

export function getMain(data) {
	let url = "/api/Main/read/" + data;
	return apiGet(url, {params: {timestamp: Math.random()}});
}

export function postMain(data) {
	return apiPost("/api/Main/edit", data);
}

export function addMain(data) {
	return apiPost("/api/Main/add", data);
}

export function deleteMain(data) {
	return apiGet("/api/Main/delete/" + data);
}
